<template>
    <div>
        <GroupAutoCop v-if="type==0"/>
        <GroupAutoOne v-if="type==1"/>
    </div>
</template>

<script>
import { reactive,ref,onMounted,computed,watch} from "vue";
import { useRoute, useRouter } from "vue-router";
import { ElMessageBox, ElMessage } from "element-plus";
import Tool from "@/assets/js/tools";
import { useStore } from "vuex";
import GroupAutoCop from "@/views/GroupManager/GroupAuto/GroupAuto"
import GroupAutoOne from "@/views/GroupManager/GroupAuto/GroupAutoOne"

export default {
    name: "GroupAutoWrap",
    components: {
      GroupAutoCop,GroupAutoOne
    },
    setup() {
        const router = useRouter();
        const store = useStore();
        const type = computed(() => store.getters.autoReplay.type)

        watch(() => store.getters.autoReplay.type, (curVal, prevVal) => {     
            
        })

        return{
            type
        }
    }
}
</script>
<style lang='scss' scoped>

</style>