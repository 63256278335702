<template>
  <div class="container">
    <el-dialog title="回复规则" width='600px' v-model="dialogVisible">
        <el-form :model="ruleForm" :rules="rules" ref="ruleFormRef" label-width="150px" class="ruleForm">
            <el-form-item label="回复规则：" prop="data.rules">
                <el-select v-model="ruleForm.data.rules" placeholder="请选择">
                  <el-option v-for="item in ruleList" :key="item.RuleID" :label="item.RuleName" :value="item.RuleID"></el-option>
                </el-select>
            </el-form-item>
        </el-form>

        
        <template #footer>
              <div class="dialog-footer">
                 <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
                 <el-button @click="dialogVisible = false">取 消</el-button>
              </div>
          </template>
    </el-dialog>
  </div>
</template>


<script>
import { nextTick, onMounted, reactive, ref, unref,watch } from "vue";
import { ElMessageBox,ElMessage } from 'element-plus';
import {PrivateReplyRuleList,PrivateReplySetRule} from "@/helper/group"
import _ from "lodash";

export default {
  name: "SelectRuleCop",
  components: {},
  setup(props, context) {
    const dialogVisible = ref(false);
    const ruleFormRef = ref(null);
    const ruleForm = reactive({
        data:{
            rules:""
        }
    });
    const rules = reactive({
        data:{
            rules: [
                { required: true,message:"请选择", trigger: "blur" },
            ]
        }

    });

    const ruleList = ref([])

    onMounted(()=>{})

    function initCop() {
       dialogVisible.value = true;
       getRuleData()
       resetData()
    }

    function getRuleData(){
        PrivateReplyRuleList().then(res=>{
            ruleList.value = res.List
            ruleForm.data.rules = ruleList.value[0].RuleID
        })
    }
    
    function resetData() {
        ruleForm.data.rules = ''
    }
   
    /*****************点击事件*********************/
    //确定表单
    const submitForm = _.debounce(
      async () => {
        const form = unref(ruleFormRef);
        if (!form) return;
        try {
            await form.validate();
            PrivateReplySetRule({RuleID:ruleForm.data.rules}).then(res=>{
                ElMessage({
                    type: "success",
                    message: "保存成功",
                });
                dialogVisible.value = false;
                context.emit("onConfirm");
            })

        } catch (error) {
          console.log(error);
        }
      },
      1000,
      {
        'leading': true, //在延迟开始前立即调用事件
        'trailing': false, //在延时结束后不调用,保证事件只被触发一次
      }
    );
    return {
      initCop,
      dialogVisible,
      ruleFormRef,
      ruleForm,
      rules,
      submitForm,
      ruleList,
      getRuleData
    };
  }
};
</script>
<style lang='scss' scoped>
.ruleForm {
  // width: 400px;
  margin: 20px 20px 0;
  .el-select {
    width: 100%;
  }
  p {
    font-size: 12px;
    color: #bbb;
  }
  .item-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    .item-group-count {
      margin: 0 10px;
    }
    span {
      color: $color-common;
    }
  }
}

</style>