<template>
  <div>
    <el-dialog :title="diaTitle+'回复任务'" width="600px" v-model="dialogVisible">
       <el-form :model="ruleForm" :rules="rules" ref="ruleFormRef" label-width="120px" class="ruleForm">
            <el-form-item label="回复内容：" prop="data.replayContent">
                <el-input v-model="ruleForm.data.replayContent" type="textarea" :rows="10" placeholder="请输入" :maxlength="999" show-word-limit/>
            </el-form-item>
        </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
          <el-button @click="dialogVisible = false">取 消</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { onMounted, reactive, ref, unref } from "vue";
import { ElMessageBox, ElMessage } from "element-plus";
import {PrivateReplyCreate,PrivateReplyDetail} from "@/helper/group"
import _ from "lodash";

export default {
  components: {},
  setup(props, context) {
    const dialogVisible = ref(false);
    const type = ref(0)
    const diaTitle = ref('添加')
    const isAdd = ref(true)

    const ruleFormRef = ref(null);
    const ruleForm = reactive({
      data:{
        replayContent: "",
      }
      
    });
    const rules = reactive({
      data:{
        replayContent: [
          { required: true, message: "请填写", trigger: "blur" },
        ]
      }

    });

    const SID = ref(null)

    function initCop(pageType = 0,item) {
      dialogVisible.value = true;
      type.value = pageType
      diaTitle.value = item ?'编辑' :'添加'
      resetData();
      loadData(item)
    }

     //重置
    function resetData() {
      ruleForm.data.replayContent=''
      isAdd.value = true
    }

    //获取
    function loadData(item){
      if(item){
        SID.value = item.TaskID
        ruleForm.data.replayContent = item.MsgContent
        isAdd.value = false
      }
    }

     //确定表单
    const submitForm = _.debounce(
      async () => {
        const form = unref(ruleFormRef);
        if (!form) return;
        try {
          await form.validate();
          let param={
            Type:type.value==0?1:2,
            TextContent:[ruleForm.data.replayContent]
          }
          if(!isAdd.value){
            param.TaskID = SID.value
          }
          PrivateReplyCreate(param).then(res=>{
            ElMessage({
              type: "success",
              message: "保存成功",
            });
            dialogVisible.value = false;
            context.emit("onConfirm");
          })
        } catch (error) {
          console.log(error);
        }
      },
      1000,
      {
        leading: true, //在延迟开始前立即调用事件
        trailing: false, //在延时结束后不调用,保证事件只被触发一次
      }
    );

    return {
      ruleFormRef,
      ruleForm,
      rules,
      diaTitle,
      dialogVisible,
      initCop,
      submitForm,
      type,
      resetData,
      isAdd,
      loadData,
      SID
    };
  },
};
</script>

<style lang="scss" scoped>
.group-box {
  display: flex;
  align-items: center;
  justify-content: center;
  :deep().el-input {
    width: 220px;
    margin: 0 20px;
  }
}
.group-box2 {
  display: flex;
  align-items: center;
  margin-left: 80px;
  //   justify-content: center;
  margin-top: 20px;
}
</style>