<template>
  <div>
    <div class="switch-cell">
        <div>是否开启1对1自动回复任务：</div>
        <el-switch v-model="openReplay" active-color="#13ce66" @change="isOpenReplay"/>
        <p style="margin-left:5px">接收对象每个自然日内只会收到一条回复内容</p>
    </div>
    
    <div class="line-height"></div>
    <div class="work-hours">
        <div class="cell-wrap">
            <p>助手上班时间</p>
            <el-button type="primary" style="margin-left:5px" @click="replayRuleAction">回复规则</el-button>
        </div> 
        <div class="wrap">
            <div class="content">
            <div v-for="(item,index) in dayList" :key="index" class="cell">
                <el-checkbox v-model="item.State" :label="item.day" @change="changeDayAction(index)"/>
                <el-input class="replytext" v-model="item.StartHour" :min="0" :max="23" oninput = "if(value<=0||value>23)value=0" type="number" :disabled="item.State==0?true:false"></el-input>
                <span>时</span>
                <el-input class="replytext" v-model="item.StartMinute" :min="0" :max="59" oninput = "if(value<=0||value>59)value=0" type="number" :disabled="item.State==0?true:false"></el-input>
                <span>分</span>
                <span>~</span>
                <el-input class="replytext" v-model="item.EndHour" :min="0" :max="23" oninput = "if(value<=0||value>23)value=0" type="number" :disabled="item.State==0?true:false"></el-input>
                <span>时</span>
                <el-input class="replytext" v-model="item.EndMinute" :min="0" :max="59" oninput = "if(value<=0||value>59)value=0" type="number" :disabled="item.State==0?true:false"></el-input>
                <span>分</span>
            </div>

            <!-- <el-checkbox-group  v-model="checkDay">
                <el-checkbox class="cell" v-for="(item,index) in dayList" :key="index" :label="item" @change="changeDayAction(index)">
                    <span>{{item.day}}:</span>
                    <el-input class="replytext" v-model="item.StartHour" :min="0" :max="23" oninput = "if(value<=0||value>23)value=0" type="number" :disabled="item.Status==0?true:false"></el-input>
                    <span>时</span>
                    <el-input class="replytext" v-model="item.StartMinute" :min="0" :max="59" oninput = "if(value<=0||value>59)value=0" type="number" :disabled="item.Status==0?true:false"></el-input>
                    <span>分</span>
                    <span>~</span>
                    <el-input class="replytext" v-model="item.EndHour" :min="0" :max="23" oninput = "if(value<=0||value>23)value=0" type="number" :disabled="item.Status==0?true:false"></el-input>
                    <span>时</span>
                    <el-input class="replytext" v-model="item.EndMinute" :min="0" :max="59" oninput = "if(value<=0||value>59)value=0" type="number" :disabled="item.Status==0?true:false"></el-input>
                    <span>分</span>
                </el-checkbox>
            </el-checkbox-group> -->
            </div>
            <el-button type="primary" class="sumbit" @click="submitAction">保 存</el-button>
        </div>
        
    </div>
    <div class="line-height"></div>
    <div class="work-content">
        <div class="cell-wrap">
            <div>上班时间回复内容</div>
            <el-button type="primary" style="margin-left:5px" @click="addReplayAction(0)">添加回复任务</el-button>
        </div>
      
        <div class="table-box">
            <el-table :data="sourceData.upWork" style="width: 100%" border>
                <el-table-column prop="MsgContent" label="回复内容" ></el-table-column>
                <el-table-column  label="操作" width="150">
                    <template #default="scope">
                        <el-button type="primary" @click="editAction(scope.row)">编辑</el-button>
                        <el-button type="primary"  @click="delAction(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>  

    </div>
    <div class="line-height"></div>
    <div class="work-content">
        <div class="cell-wrap">
            <div>下班时间回复内容</div>
            <el-button type="primary" style="margin-left:5px" @click="addReplayAction(1)">添加回复任务</el-button>
        </div>
        <div class="table-box">
            <el-table :data="sourceData.downWork" style="width: 100%" border >
                <el-table-column prop="MsgContent" label="回复内容" ></el-table-column>
                <el-table-column  label="操作" width="150">
                    <template #default="scope">
                        <el-button type="primary" @click="editAction(scope.row)">编辑</el-button>
                        <el-button type="primary"  @click="delAction(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
    <AddAutoContent ref="addAutoContentRef" @onConfirm="sureAction"/>
    <SelectRuleCop ref="selectRuleRef" @onConfirm="sureAction"/>
  </div>
</template>

<script>
import { unref, reactive, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ElMessageBox, ElMessage } from "element-plus";
import AddAutoContent from "@/components/GroupManager/AddAutoContent"
import SelectRuleCop from "@/components/GroupManager/SelectRuleCop"
import {ConfigGetPrivateReply,ConfigSetPrivateReply,PrivateReplyWorkTime,PrivateReplySetWorkTime,PrivateReplyTaskList,PrivateReplyDelete} from "@/helper/group"
import tools from "@/assets/js/tools";

export default {
  name: "GroupAutoOne",
  components: {AddAutoContent,SelectRuleCop},
  setup() {
    const router = useRouter();
    const addAutoContentRef =ref(null)
    const selectRuleRef =ref(null)
    const isDisabled = ref(true)
    const dayList= ref([
        {
            DayOfWeek:1,
            StartHour:"",
            StartMinute:"",
            EndHour:"",
            EndMinute:"",
            Status:0
        },
        {
            DayOfWeek:2,
            StartHour:"",
            StartMinute:"",
            EndHour:"",
            EndMinute:"",
            Status:0
        },
        {
            DayOfWeek:3,
            StartHour:"",
            StartMinute:"",
            EndHour:"",
            EndMinute:"",
            Status:0
        },{
            DayOfWeek:4,
            StartHour:"",
            StartMinute:"",
            EndHour:"",
            EndMinute:"",
            Status:0
        },{
            DayOfWeek:5,
            StartHour:"",
            StartMinute:"",
            EndHour:"",
            EndMinute:"",
            Status:0
        },{
            DayOfWeek:6,
            StartHour:"",
            StartMinute:"",
            EndHour:"",
            EndMinute:"",
            Status:0
        },{
            DayOfWeek:0,
            StartHour:"",
            StartMinute:"",
            EndHour:"",
            EndMinute:"",
            Status:0
        }
    ])

    const checkDay= ref([])
    const openReplay = ref(false)
    const sourceData = reactive({
        upWork:[],
        downWork:[]
    })

    onMounted(() => {
        loadData()
    });

    function loadData(){
        requestReply()
        requestWorkTime()
        requestUpTask()
        requestDownTask()
    }

    function requestReply(){
        ConfigGetPrivateReply().then(res=>{
            openReplay.value = res.Status ==1 ? true : false
        })
    }

    function requestWorkTime(){
        checkDay.value = []
        PrivateReplyWorkTime().then(res=>{
            dayList.value = res.List
            for(let item of dayList.value){
                item.day=changeState(item.DayOfWeek)
                item.State = item.Status==1?true:false
                if(item.State){
                    checkDay.value.push(item)
                }
            }
        })
    }

    function requestUpTask(){
        let param={
            Type:1,
            PageNum:1,
            PageSize:999,
        }
         PrivateReplyTaskList(param).then(res=>{
            sourceData.upWork = res.List
        })
        
    }

    function requestDownTask(){
        let param={
            Type:2,
            PageNum:1,
            PageSize:999,
        }
         PrivateReplyTaskList(param).then(res=>{
            sourceData.downWork = res.List
        })
    }

    //转化
    function changeState(item){
        if(item==0){
            return '周日'
        }else if(item==1){
            return '周一'
        }else if(item==2){
            return '周二'
        }else if(item==3){
            return '周三'
        }else if(item==4){
            return '周四'
        }else if(item==5){
            return '周五'
        }else if(item==6){
            return '周六'
        }
    }
    /****************************点击事件***********************************/
    //保存上班时间
    function submitAction(){
        let arr=[]
        for(let item of checkDay.value){
            let obj={
                DayOfWeek:item.DayOfWeek,
                StartHour: parseInt(item.StartHour),
                StartMinute: parseInt(item.StartMinute),
                EndHour: parseInt(item.EndHour),
                EndMinute:parseInt(item.EndMinute),
                Status: item.State?1:0
            }
            arr.push(obj)
        }
         for(let item of checkDay.value){
            if(item.Status==1){
                if(parseInt(item.StartHour)>parseInt(item.EndHour)){
                    ElMessage({
                        type:"info",
                        message:"开始时间不能大于结束时间，请检查"
                    })
                    return
                }else if((parseInt(item.StartHour)==parseInt(item.EndHour))&&(parseInt(item.StartMinute)>parseInt(item.EndMinute))){
                    ElMessage({
                        type:"info",
                        message:"开始时间不能大于结束时间，请检查"
                    })
                    return
                }
            }
        }
        PrivateReplySetWorkTime(arr).then(res=>{
            requestWorkTime()
        })
    }

    const holdIndex = ref(0)
    //开关
    function isOpenReplay(){
        ConfigSetPrivateReply({Status:openReplay.value?1:0}).then(res=>{
            requestReply()
        })
    }

    //选择规则
    function replayRuleAction(){
        selectRuleRef.value.initCop()
    }
    //确定规则
    function sureAction(){
        loadData()
    }

    function changeDayAction(index){
        let item = dayList.value[index]
        if(item.State){
            checkDay.value.push(item)
        }else{
            checkDay.value.splice(checkDay.value.indexOf(item),1)
        }
    }

    //添加回复任务
    function addReplayAction(index){
        holdIndex.value = index
        addAutoContentRef.value.initCop(index)
    }

    //编辑回复任务
    function editAction(item){
        addAutoContentRef.value.initCop(holdIndex.value,item)
    }

    //删除任务
    function delAction(item){
        ElMessageBox.confirm(`您确定要删除该回复内容吗？`, "确认提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        }).then(() => {
            PrivateReplyDelete({TaskID:item.TaskID}).then(res=>{
                ElMessage({
                    type: "success",
                    message: "删除成功"
                });
                loadData()
            })
        }).catch(() => {
            ElMessage({
                type: "info",
                message: "已取消"
            });
        });
    }
    return {
        replayRuleAction,
        dayList,
        changeDayAction,
        isOpenReplay,
        isDisabled,
        checkDay,
        openReplay,
        holdIndex,
        addReplayAction,
        sourceData,
        addAutoContentRef,
        editAction,
        delAction,
        selectRuleRef,
        submitAction,
        loadData,
        sureAction,
        requestUpTask,
        requestDownTask,
        
    };
  },
};
</script>
<style lang='scss' scoped>
.cell-wrap{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
}
.wrap{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10px;
}
.switch-cell{
    background: #eee;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
}

.work-hours{
    padding: 0 10px;
    padding-top: 10px;
    box-sizing: border-box;
    font-size: 15px;
    color: #333;
    .replay-right{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .content{
        width: 100%;
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        .cell{
            // width: 480px;
            padding: 10px;
            margin-right: 0!important;
            box-sizing: border-box;
        }
        :deep() .el-input {
            width: 80px;
        }
    }
}
.work-content{
    background: #fff;
    padding: 10px;
}
</style>