<template>
  <div>
    <div class="table-box">
        <div class="list-title">
			<el-button type="primary" @click="addMission">添加回复任务</el-button>
            <div class="list-right">
                <div>是否开启自动回复任务：</div>
                <el-switch v-model="openMission" active-color="#13ce66" @change="isOpenMission" :active-value="1" :inactive-value="0"/>
                <div class="action-wrap">
                    <router-link :to="{path:'/whitelist'}">（白名单用户</router-link>
                    <span>不触发）</span>
                </div>
            </div>
		</div>
     <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="TaskName" label="任务名称"></el-table-column>
        <el-table-column prop="KeywordCount" label="关键词数量"></el-table-column>
        <el-table-column prop="GroupCount" label="关联群数量"></el-table-column>
        <el-table-column prop="ReplyTime" label="回复时间"></el-table-column>
        <el-table-column prop="CreateTime" label="创建时间"></el-table-column>
        <el-table-column label="操作" width="180">
          <template #default="scope">
              <div class="action-wrap">
                  <el-button type="primary" @click="editAction(scope.row)">编辑</el-button>
                  <el-button type="primary" @click="delAction(scope.row)">删除</el-button>
              </div>
          </template>
        </el-table-column>
     </el-table>
      <TableFoot
        :pagination="pageObj" 
        @changePage="changePage" 
        @changeSize="changeSize" />
    </div>
  </div>
</template>

<script>

import { reactive,ref,onMounted} from "vue";
import { useRoute, useRouter } from "vue-router";
import TableFoot from "@/components/TableFoot";
import { ElMessageBox,ElMessage } from 'element-plus';
import {AutoReplyGetList,ConfigGetAutoReply,ConfigSetAutoReply,AutoReplyDelete} from "@/helper/group"
import Tool from '@/assets/js/tools'

export default {
  name: "GroupAuto",
  components: {
      TableFoot
    },
  setup() {
    const router = useRouter();
    const tableData=ref([])
    const openMission=ref(0)

    onMounted(()=>{
      getAuto()
      searchAction()
    })

    function searchAction() {
      let param={
        PageNum:pageObj.PageIndex,
        PageSize:pageObj.PageSize,
      }
        AutoReplyGetList(param).then(res=>{
          pageObj.TotalRow=res.TotalCount
          tableData.value=res.List
        })
    }
   
   function getAuto(){
    ConfigGetAutoReply().then(res=>{
      openMission.value = res.Status
    })
   }
    /****************点击事件*******************/
    //点击添加
    function addMission() {
      router.push({ name: "CreateAutoMission" });
    }
    
    //是否开启
    function isOpenMission() {
      ConfigSetAutoReply({Status:openMission.value}).then(res=>{
        getAuto()
      })
    }

    //编辑
    function editAction(item){
      router.push({ name: "CreateAutoMission",query:{SID:item.ID} });
    }

    //删除
    function delAction(item) {
        ElMessageBox.confirm(`是否删除此回复任务？`, "确认提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          AutoReplyDelete({ TaskID: item.ID }).then((res) => {
            ElMessage({
              type: "success",
              message: "删除成功!",
            });
            searchAction();
          });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "已取消",
          });
        });
        
    }

    /****************TableFoot*******************/
    const pageObj = reactive({
        TotalRow: 0,
        PageIndex: 1,
        PageSize: 10,
    })
    //页码改变
    function changePage(index) {
        pageObj.PageIndex = index;
        searchAction();
    };
    function changeSize(size) {
        pageObj.PageSize = size;
        pageObj.PageIndex = 1;
        searchAction();
    }

    return {
      changePage,
      changeSize,
      pageObj,
      tableData,
      openMission,
      getAuto,
      editAction,
      delAction,
      searchAction,
      addMission,
      isOpenMission
    };
  },
};
</script>
<style lang="scss" scoped>
.list-right{
    display: flex;
    align-items: center;
}
</style>